import React, { useState } from "react";
import emailjs from 'emailjs-com';
// import './Contact.css';

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    position: '',
    resume: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (p) => {
    p.preventDefault();
    // Mobile number validation
    if (formData.mobile.length !== 10) {
      alert("Mobile number must be exactly 10 digits long.");
      return;
    }

    const serviceId = 'service_3joh22e';
    const templateId = 'template_fh1ou8l';
    const publicKey = '8guKYuvqShg9iEloy';

    const templateParams = {
      from_name: formData.name,
      from_mobile: formData.mobile,
      from_email: formData.email,
      position: formData.position,
      resume: formData.resume,
    };

    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully", response);
        alert("Application submitted successfully!");
        setFormData({ name: '', mobile: '', email: '', position: '', resume: '' });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("Failed to submit application.");
      });
  };

  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div className="mt-3 mb-3 p-3" style={{ width: "70%" }}>
        <form className="shadow-lg p-3 mb-3 rounded" style={{ backgroundColor: "#F4F4F5" }} onSubmit={handleSubmit}>
          <h2>Application Form</h2>
          <div className="mb-1 mt-2">
            <label htmlFor="name" className="form-label">
              Your Name <span className="fs-5 text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control border border-success"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="mobile" className="form-label">
              Phone Number <span className="fs-5 text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control border border-success"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
            />

            <label htmlFor="email" className="form-label">
              Your Email<span className="fs-5 text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control border border-success"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="position" className="form-label">
              Position<span className="fs-5 text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control border border-success"
              id="position"
              name="position"
              value={formData.position}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="resume" className="form-label">
              Resume link<span className="fs-5 text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control border border-success"
              id="resume"
              name="resume"
              value={formData.resume}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary mb-3 ps-3 pe-3">
            <h2 className="fs-4">Submit</h2>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ApplicationForm;
