// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/Image/careers.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.careerBkImg{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    height: 700px;
    width: 100%;
}
.jobTitle{
text-align: left;
font-family: calibiri;
font-size: larger

}
@media(max-width:576px){
    .careerBkImg{
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
        background-size: cover;
        height: 400px;
        width: 100%;
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/Career.css"],"names":[],"mappings":"AAAA;IACI,yDAAoD;IACpD,sBAAsB;IACtB,aAAa;IACb,WAAW;AACf;AACA;AACA,gBAAgB;AAChB,qBAAqB;AACrB;;AAEA;AACA;IACI;QACI,yDAAoD;QACpD,sBAAsB;QACtB,aAAa;QACb,WAAW;IACf;;AAEJ","sourcesContent":[".careerBkImg{\r\n    background-image: url('../public/Image/careers.png');\r\n    background-size: cover;\r\n    height: 700px;\r\n    width: 100%;\r\n}\r\n.jobTitle{\r\ntext-align: left;\r\nfont-family: calibiri;\r\nfont-size: larger\r\n\r\n}\r\n@media(max-width:576px){\r\n    .careerBkImg{\r\n        background-image: url('../public/Image/careers.png');\r\n        background-size: cover;\r\n        height: 400px;\r\n        width: 100%;\r\n    }\r\n    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
