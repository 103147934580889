import { useNavigate} from "react-router-dom";
import './Career.css'
import { useState, useEffect } from 'react';

function Career() {
  const navigate = useNavigate();

  const [printdata, setPrintData] = useState([]);

async function send() {
  try {
    const response = await fetch("https://13.233.116.213:4000/get");
    const res = await response.json();
    setPrintData(res);
  } catch (err) {
    console.log(err);
  }
}

useEffect(() => {
  send(); // Call send() when the component mounts
}, []); // Empty dependency array ensures this runs only once

  return (
    <>
      <div className="card" style={{width:'101%', paddingLeft:"1px" }}>
        <div className="card-body careerBkImg"></div>
      </div>

      <br />

      <div style={{margin:'40px'}}>

        {printdata.map((e, index) => (
  <div  key={index}>
  <ul>
  <li><p className=""    style={{ cursor: "pointer",marginLeft:"10px" }} onClick={() => navigate('/job', { state: { robotId: e._id } })}> {e.jobdescription}. </p>
</li>
   </ul>
  </div>
))}
 
      
       
      </div> <br /><br /><br />
    </>
  );
}
export default Career;
