// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/Image/BDM Img.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainBdm{
    margin-top: 5%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    height: auto;
    margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Bdm.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yDAAqD;IACrD,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".mainBdm{\r\n    margin-top: 5%;\r\n    background-image: url('../public/Image/BDM Img.jpeg');\r\n    background-size: cover;\r\n    height: auto;\r\n    margin-left: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
